require('/ui/components/dropdowntrigger/dropdowntrigger.module.scss.module.css');

export const dropdownMargin = "2";
export const smallViewSupport = "_small-view-support_aac8c_1";
export const smallViewportSupport = "_small-viewport-support_aac8c_1";
export const fontSizing = "_font-sizing_aac8c_1";
export const containerCollapsed = "_container-collapsed_aac8c_1";
export const portal = "_portal_aac8c_1";
export const trigger = "_trigger_aac8c_1";
export const manaUiTheme = "_mana-ui-theme_aac8c_1";
export const triggerCombo = "_trigger-combo_aac8c_1";
export const chromeless = "_chromeless_aac8c_1";
export const neutral = "_neutral_aac8c_1";
export const ghost = "_ghost_aac8c_1";
export const primary = "_primary_aac8c_1";
export const collapsed = "_collapsed_aac8c_1";
